<template>
    <div id="Security">
        <div class="container">
            <vue-element-loading :active="save" spinner="bar-fade-scale"/>
            <div class="mb-4 pb-4 underline" v-if="!otp_check">
                <button class="tit btn btn-primary btn-block text-left d-flex justify-content-between" @click="CreateOTP">
                    <span>
                        Google OTP {{otpStatus(otp_check)}}
                    </span>
                    <span>
                        <span v-if="!otp_check">{{$t('security.1')}} </span> 
                        <span v-else>{{$t('security.2')}} </span>
                        <i class="far fa-chevron-down"></i>
                    </span>
                </button>
                
                <div class="flex flex-wrap justify-between" v-if="otp_box">
                    <div class="w-100 m-3 qrcode_box">
                        <vue-qrcode :value="qr_url" style='width:100%'/>
                    </div>
                    <div class="w-100 mb-5">
                        <div class="form-group mb-2">
                            <div class="r_btn">
                                <input
                                placeholder="OTP CODE"
                                v-model="otp_code" disabled
                                class="form-control" />
                                <button class="btn" @click="CopyWallet"><i class="far fa-copy"></i></button>
                            </div>
                        </div>
                        <div class="form-group mb-2">
                            <input
                            :placeholder="$t('security.14')"
                            v-model="otp_token" 
                            class="form-control" />
                        </div>
                        <div>
                            <button class="btn btn-primary btn-block" @click='OTPConfirm'>{{$t('security.15')}}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form_box">
                <h6 class="tit main_txt">{{$t('security.3')}}</h6>
                <div class="form-group unit">
                    <div class="d-flex align-items-center justify-content-between mb-2 ml-2">
                        <span>E-Mail</span>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="l_email" v-model="l_email" @change="AuthCheck('login_email', l_email)">
                            <label class="custom-control-label" for="l_email"></label>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between ml-2">
                        <span>Google OTP</span>
                        <div class="custom-control custom-switch">
                            
                            <input type="checkbox" class="custom-control-input" id="l_otp" v-model="l_otp" :disabled="!otp_check" @change="AuthCheck('login_otp', l_otp)">
                            <label class="custom-control-label" for="l_otp"></label>
                        </div>
                    </div>
                </div>

                <h6 class="tit main_txt">{{$t('security.4')}}</h6>
                <div class="form-group unit">
                    <div class="d-flex align-items-center justify-content-between mb-2 ml-2">
                        <span>E-Mail</span>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="t_email"  v-model="t_email" @change="AuthCheck('send_email', t_email)">
                            <label class="custom-control-label" for="t_email"></label>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-2 ml-2">
                        <span>Google OTP</span>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="t_otp"  v-model="t_otp" :disabled="!otp_check" @change="AuthCheck('send_otp', t_otp)">
                            <label class="custom-control-label" for="t_otp"></label>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between ml-2">
                        <span>{{$t("signup.14")}}</span>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="t_pin"  v-model="t_pin" @change="AuthCheck('send_pin', t_pin)">
                            <label class="custom-control-label" for="t_pin"></label>
                        </div>
                    </div>
                </div>

                <h6 class="tit main_txt">{{$t("package.3")}}</h6>
                <div class="form-group unit">
                    <div class="d-flex align-items-center justify-content-between mb-2 ml-2">
                        <span>E-Mail</span>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="b_email"  v-model="b_email" @change="AuthCheck('buy_email', b_email)">
                            <label class="custom-control-label" for="b_email"></label>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-2 ml-2">
                        <span>Google OTP</span>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="b_otp"  v-model="b_otp" :disabled="!otp_check" @change="AuthCheck('buy_otp', b_otp)">
                            <label class="custom-control-label" for="b_otp"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="infoPp" v-if="infoPp">
                <div class="dimm" @click="infoPpOff()"></div>
                <div class="content pt-4">
                    <div class="form_box pt-2" v-if="emailArea">
                        <div class="form-group line">
                            <div class="d-flex align-items-center justify-content-between">
                                <label>{{$t('transaction.11')}}</label>
                            </div>
                            <div class="r_btn">
                                <input type="password" class="form-control" v-model="email_code" :placeholder="$t('transaction.12')" :disabled="email_chk">
                                <button type="button" class="btn btn-default text-right pr-0" :disabled="email_chk" @click="EmailCodeCheck">{{$t('transaction.13')}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="form_box pt-2" v-if="otpArea">
                        <div class="form-group line">
                            <label>OTP {{$t('transaction.13')}}</label>
                            <div class="r_btn">
                                <input type="password" class="form-control" v-model="otp_code" :placeholder="`OTP ${$t('transaction.14')}`" :disabled="otp_chk">
                                <button type="button" class="btn btn-default text-right pr-0" :disabled="otp_chk" @click="OTPCodeCheck">{{$t('transaction.13')}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="form_box pt-2" v-if="pinArea">
                        <div class="form-group line">
                            <label>PIN {{$t('transaction.13')}}</label>
                            <div class="r_btn">
                                <input type="password" class="form-control" v-model="pin_code" :placeholder="`PIN ${$t('transaction.14')}`" :disabled="pin_chk">
                                <button type="button" class="btn btn-default text-right pr-0" :disabled="pin_chk" @click="PinCodeCheck">{{$t('transaction.13')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'

export default {
    components: {
        VueQrcode,
    },
    data(){
        return{
            save: this.false,
            title:this.$t("menu.7"),
            prevP:true,
            pageD: false,
            lang: this.$route.params.lang,

            otp_check: false,
            otp_box: false,
            otp_code: "",
            qr_url:'',
            otp_token:'',

            info: {},

            l_email: false,
            l_otp: false,
            t_email: false,
            t_otp: false,
            t_pin: false,
            b_email: false,
            b_otp: false,
            infoPp : false,
            emailArea:false,
            otpArea:false,
            pinArea : false,
            email_chk:false,
            target:'',
            value:'',
            email_code :'',
            otp_code :'',
            pin_code :'',
            otp_chk : false,
            pin_chk : false,
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },
    methods: {        
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                const code = res.data.code;
                if(code == '200'){                     
                    this.CheckOTP();
                    this.AuthInfo();
                }else if(code == '9999'){
                    this.$alert(this.$t('signout.1')).then(()=>{
                        this.$store.dispatch('SETLOGOUT').then(()=>{
                            this.$router.push({name:'Signin'});
                        });
                    })
                }
                }
            })
        },
        CheckOTP(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/setting/otp_check`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        if(res.data.cnt ==0){
                            this.otp_check = false;
                        }else{
                            this.otp_check = true;
                        }
                    }
                }
            })
        },
        infoPpOff: function(){
            this.infoPp = false;
        },
        OTPCodeCheck : function(){
            const api = this.$store.state.baseURL;
            const code = this.otp_code;
            this.$http.post(`${api}/member/common/otp_code_check`,{code}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('transaction.23')).then(
                        ()=>{
                            this.AuthChange(this.target,this.value)
                        })
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t('transaction.24'));
                    }
                }

            })
        },
        CreateOTP(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/setting/otp_generate`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.otp_code = res.data.otp_code
                        this.qr_url = res.data.qr_url
                        this.otp_box = !this.otp_box;
                    }
                }
            })
        },
        AuthInfo: function() {
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/setting/my_security_info`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        const info = res.data.info;
                        this.info = info;
                        
                        if(info.login_email == "Y") this.l_email = true;
                        else this.l_email = false;

                        if(info.login_otp == "Y") this.l_otp = true;
                        else this.l_otp = false;

                        if(info.send_email == "Y") this.t_email = true;
                        else this.t_email = false;

                        if(info.send_otp == "Y") this.t_otp = true;
                        else this.t_otp = false;

                        if(info.send_pin == "Y") this.t_pin = true;
                        else this.t_pin = false;

                        if(info.buy_email == "Y") this.b_email = true;
                        else this.b_email = false;

                        if(info.buy_otp == "Y") this.b_otp = true;
                        else this.b_otp = false;

                    }
                }
            })
        },
        otpStatus: function(otpBool){
            if(otpBool){
                return this.$t('security.7')
            }else{
                return this.$t('security.8')
            }
        },
        CopyWallet: function () {
            this.$copyText(this.otp_code).then( 
            () =>{
                this.$fire({title:this.$t('security.9'),html : this.otp_code});
            })
        },
        OTPConfirm : function(){
            const otp_code = this.otp_code;
            const otp_token = this.otp_token;

            if(otp_token ==''){
                this.$alert(this.$t('security.10'))
                return false;
            }

            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/setting/otp_auth`,{otp_code,otp_token}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('security.11')).then(
                            ()=>{
                                this.CheckOTP();
                                this.otp_box=false;
                            }
                        )
                        
                    }else{
                        this.$alert(this.$t('security.12'));
                    }
                }
            })
        },
        
        AuthCheck: function(target, v){            
            this.target = target;
            this.value = v;
            this.infoPp = true;
            if(v ==true){
                this.AuthChange(target, v);           
            }else{    
                this.$confirm(this.$t('security.18')).then(()=> { 
                    if(target =='send_otp' || target =='login_otp' || target =='buy_otp'){                    
                        this.otpArea = true                    
                    }else if(target =='send_email' || target =='login_email' || target =='buy_email'){
                        this.emailArea = true
                        this.EmailSecurity();
                    }else{
                        this.pinArea = true                    
                    }
                }).catch(()=>{
                    this.target = '';
                    this.value = '';
                    this.infoPp=false;
                    this.AuthInfo();
                });    
            }
        },
        EmailSecurity : function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/common/email_security`,{}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('transaction.20'));
                    }
                }
            })
        },
        EmailCodeCheck : function(){
            const api = this.$store.state.baseURL;
            const code = this.email_code;
            
            this.$http.post(`${api}/member/common/email_code_check`,{code}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('transaction.21')).then(
                        ()=>{                            
                            this.AuthChange(this.target,this.value)
                        }
                    )
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t('transaction.22'))
                    }
                }
            })
        },
        PinCodeCheck : function(){
            const api = this.$store.state.baseURL;
            const code = this.pin_code;
            
            this.$http.post(`${api}/member/common/pin_code_check`,{code}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t('transaction.15')).then(
                        ()=>{                            
                            this.AuthChange(this.target,this.value)
                        }
                    )
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t('transaction.22'))
                    }
                }
            })
        },        
        AuthChange: function(target, v) {
            var value = "";
            if(v) value = "Y";
            else value = "N";
            const api = this.$store.state.baseURL;            
            this.$http.post(`${api}/member/setting/security_change`,{target, value}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.AuthInfo();    
                        this.infoPp = false;
                        this.otpArea = false;
                        this.emailArea = false;
                        this.pinArea = false;
                    }
                }
            })
        }
    }
}
</script>


<style scoped>

    .form-group.unit{
        /* border-bottom: 1px solid #eaeaea; */
        background-color: #fff;
        border-radius: 6px;
        font-size: .875em;
        padding: 1em;
        margin-bottom: 1.3em;    
        /* box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14); */
    }
    .tit{
        font-size: .875em;
    }
    .custom-switch .custom-control-label::after{
        top: 3px;
        left: -36px;
        width: 18px;
        height: 18px;
    }
    .custom-switch .custom-control-input:checked ~ .custom-control-label::after{
        border: 1px solid #4B67F9;
    }
    .custom-control-input:checked ~ .custom-control-label::before{
        border-color: #4B67F9;
        background-color: #4B67F9;
    }
</style>