<template>
    <div id="Language">
        <div class="container">
            <div class="form_box">
                <div class="form-group" v-for="(item, index) in langs" :key="index">
                    <input type="radio" v-model="lang" :value="item.code" :id="`lang_${item.code}`" @change="SetLocale" hidden>
                    <label :for="`lang_${item.code}`" class="d-flex align-items-center justify-content-between">
                        <span>{{item.language}}</span>
                        <span class="chk_icon"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return{
            title:this.$t("menu.8"),
            prevP:true,
            pageD: false,
            language: this.$route.params.lang,
            langs: [],
            lang: 'en'
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        this.lang = this.language;
                
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },
    methods: {    
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                const code = res.data.code;
                if(code == '200'){      
                    this.LangList();
                }else if(code == '9999'){
                    this.$alert(this.$t('signout.1')).then(()=>{
                        this.$store.dispatch('SETLOGOUT').then(()=>{
                            this.$router.push({name:'Signin'});
                        });
                    })
                }
                }
            })
        },      
        SetLocale: function(){            
            const locale= this.lang;
            this.$router.push({
                params: {lang: locale}
            }).then(
                ()=>{
                    this.$router.go();
                }
            )            
        },
        LangList : function(){
			const api = this.$store.state.baseURL;

			this.$http.post(`${api}/member/setting/lang/list`,{}).then(
			res => {
				if(res.status==200){
					if(res.data.code=='200'){                        
                        this.langs = res.data.list;
					}
				}
			})
        },
    },
}
</script>

<style scoped>
    .form-group{
        border-bottom: 1px solid #eaeaea;
        margin-top: 1rem;
        margin-bottom: 0;
        padding-bottom: 1rem;
    }
    .form-group input[type=radio]+label{
        margin-bottom: 0;
    }
    .form-group input[type=radio]+label .chk_icon{
        width: 20px;
        height: 20px;
        display: block;
        background-color: #eaeaea;
        border-radius: 100%;
    }
    .form-group input[type=radio]:checked+label .chk_icon{
        background-color: #4B67F9;
    }
</style>