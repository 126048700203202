<template>
    <div id="Security">
        <div class="container">
            <vue-element-loading :active="save" spinner="bar-fade-scale"/>
            <div class="form_box">
                <h6 class="tit main_txt">{{$t('security.19')}}</h6>
                <div class="form-group unit">
                    <div class="d-flex align-items-center justify-content-between mb-3 ml-2" v-for="(item, index) in coin_list" :key="index">
                        <span>{{item.symbol}}</span>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" :id="`coin_${item.no}`" v-model="check_coin" :value="item.no" @change="CoinSettingChg">
                            <label class="custom-control-label" :for="`coin_${item.no}`"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'

export default {
    components: {
        VueQrcode,
    },
    data(){
        return{
            save: this.false,
            title:this.$t("menu.22"),
            prevP:true,
            pageD: false,
            
            check_coin: [],
            
            coin_list: [],
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },
    methods: {        
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                const code = res.data.code;
                if(code == '200'){    
                    this.CoinInfo();
                    this.MemDashbordCoinInfo();
                }else if(code == '9999'){
                    this.$alert(this.$t('signout.1')).then(()=>{
                        this.$store.dispatch('SETLOGOUT').then(()=>{
                            this.$router.push({name:'Signin'});
                        });
                    })
                }
                }
            })
        },
        CoinInfo() {
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/coin_info`, {}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.coin_list = res.data.info;
                        
                    }
                }
            })
        },
        MemDashbordCoinInfo() {
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/setting/coin/list`, {}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        const info = res.data.info;
                        this.check_coin = info.split(',');
                    }
                }
            })
        },
        CoinSettingChg() {
            const coin_in = this.check_coin.join(',');
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/setting/coin/set`, {coin_in}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.$alert(this.$t('security.20'));
                    }
                    else {
                        this.$alert(this.$t('security.21'));
                        this.MemDashbordCoinInfo();
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

    .form-group.unit{
        /* border-bottom: 1px solid #eaeaea; */
        background-color: #fff;
        border-radius: 6px;
        font-size: .875em;
        padding: 1em;
        margin-bottom: 1.3em;    
        /* box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14); */
    }
    .tit{
        font-size: .875em;
    }
    .custom-switch .custom-control-label::after{
        top: 3px;
        left: -36px;
        width: 18px;
        height: 18px;
    }
    .custom-switch .custom-control-input:checked ~ .custom-control-label::after{
        border: 1px solid #4B67F9;
    }
    .custom-control-input:checked ~ .custom-control-label::before{
        border-color: #4B67F9;
        background-color: #4B67F9;
    }
</style>